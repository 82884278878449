import { Box, Button, Stack, Title } from "@mantine/core";
import DiceSelect from "./DiceSelect";
import React, { useState } from "react";
import DiceResult from "./DiceResult";
import { getDiceValue, rollRandomResult } from "./Dice";

export default function SimpleDiceRoll() {
  let [diceType, setDiceType] = useState("d20");
  const [rollResult, setRollResult] = useState("20");

  function rollDice() {
    setRollResult(rollRandomResult(getDiceValue(diceType)));
  }

  function handleDiceChange(diceType) {
    let diceValue = getDiceValue(diceType);
    setDiceType(diceType);
    setRollResult(diceValue.toString());
  }

  return (
    <Box mt={5}>
      <Stack align="center" gap="md">
        <Title order={1}>Dice Roller</Title>
        <DiceSelect value={diceType} change={handleDiceChange}></DiceSelect>
        <DiceResult rollResult={rollResult}></DiceResult>
        <Button
          variant="filled"
          onClick={(e) => rollDice(e.currentTarget.value)}
          color="pink"
        >
          Roll Dice
        </Button>
      </Stack>
    </Box>
  );
}

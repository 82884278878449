import {
  charmId,
  defenseId,
  magicId,
  skillId,
  speedId,
  strengthId,
} from "../character/Stats";
import createCharacter from "../character/Character";

export const amWorldName = "Arranged Marriage AU";

const dimitriStats = new Map([
  [strengthId, 5],
  [magicId, -3],
  [speedId, -1],
  [charmId, 3],
  [skillId, 3],
  [defenseId, 3],
]);

const felixStats = new Map([
  [strengthId, 4],
  [magicId, 3],
  [speedId, 5],
  [charmId, -5],
  [skillId, 5],
  [defenseId, -2],
]);

const alricStats = new Map([
  [strengthId, 2],
  [magicId, 2],
  [speedId, -2],
  [charmId, 3],
  [skillId, 5],
  [defenseId, 0],
]);

const echoStats = new Map([
  [strengthId, -2],
  [magicId, 2],
  [speedId, 2],
  [charmId, 5],
  [skillId, 5],
  [defenseId, -2],
]);

const summerStats = new Map([
  [strengthId, 2],
  [magicId, 2],
  [speedId, 0],
  [charmId, 5],
  [skillId, 3],
  [defenseId, -2],
]);

const naveeStats = new Map([
  [strengthId, 3],
  [magicId, 1],
  [speedId, 4],
  [charmId, -1],
  [skillId, 3],
  [defenseId, 0],
]);

const killuaStats = new Map([
  [strengthId, 3],
  [magicId, 1],
  [speedId, 3],
  [charmId, 0],
  [skillId, 1],
  [defenseId, 2],
]);

const gaeliStats = new Map([
  [strengthId, -1],
  [magicId, 2],
  [speedId, -1],
  [charmId, 3],
  [skillId, 5],
  [defenseId, 1],
]);

const sylvainStats = new Map([
  [strengthId, 3],
  [magicId, 2],
  [speedId, -1],
  [charmId, 5],
  [skillId, 2],
  [defenseId, 3],
]);

const eugeoStats = new Map([
  [strengthId, 2],
  [magicId, 4],
  [speedId, 1],
  [charmId, 1],
  [skillId, 1],
  [defenseId, 1],
]);

const danStats = new Map([
  [strengthId, 2],
  [magicId, 4],
  [speedId, 1],
  [charmId, 2],
  [skillId, 4],
  [defenseId, -3],
]);

const starkStats = new Map([
  [strengthId, 5],
  [magicId, -5],
  [speedId, 2],
  [charmId, 2],
  [skillId, 1],
  [defenseId, 5],
]);

const hubertStats = new Map([
  [strengthId, -4],
  [magicId, 5],
  [speedId, 5],
  [charmId, -2],
  [skillId, 5],
  [defenseId, 1],
]);

const veldoraStats = new Map([
  [strengthId, 5],
  [magicId, 5],
  [speedId, -3],
  [charmId, -5],
  [skillId, 5],
  [defenseId, 3],
]);

const rodrigueStats = new Map([
  [strengthId, 2],
  [magicId, 3],
  [speedId, 1],
  [charmId, 3],
  [skillId, 3],
  [defenseId, -2],
]);

const dedueStats = new Map([
  [strengthId, 5],
  [magicId, -5],
  [speedId, -3],
  [charmId, 4],
  [skillId, 4],
  [defenseId, 5],
]);

const renStats = new Map([
  [strengthId, 5],
  [magicId, -3],
  [speedId, 2],
  [charmId, -3],
  [skillId, 4],
  [defenseId, 5],
]);

const gojoStats = new Map([
  [strengthId, 2],
  [magicId, 2],
  [speedId, 1],
  [charmId, -5],
  [skillId, 5],
  [defenseId, 5],
]);

const edelgardStats = new Map([
  [strengthId, 5],
  [magicId, 2],
  [speedId, -5],
  [charmId, 4],
  [skillId, 1],
  [defenseId, 3],
]);

// TODO

const kyojuroStats = new Map([
  [strengthId, 3],
  [magicId, 2],
  [speedId, -1],
  [charmId, 5],
  [skillId, 2],
  [defenseId, 3],
]);

const shotoStats = new Map([
  [strengthId, 3],
  [magicId, 2],
  [speedId, -1],
  [charmId, 5],
  [skillId, 2],
  [defenseId, 3],
]);

const asheStats = new Map([
  [strengthId, 3],
  [magicId, 2],
  [speedId, -1],
  [charmId, 5],
  [skillId, 2],
  [defenseId, 3],
]);

const yuriStats = new Map([
  [strengthId, 3],
  [magicId, 2],
  [speedId, -1],
  [charmId, 5],
  [skillId, 2],
  [defenseId, 3],
]);

const dimitri = createCharacter("Dimitri", amWorldName, dimitriStats);
const felix = createCharacter("Felix", amWorldName, felixStats);
const alric = createCharacter("Alric", amWorldName, alricStats);
const echo = createCharacter("Echo", amWorldName, echoStats);
const summer = createCharacter("Summer", amWorldName, summerStats);
const navee = createCharacter("Navee", amWorldName, naveeStats);
const sylvain = createCharacter("Sylvain", amWorldName, sylvainStats);
const rodrigue = createCharacter("Rodrigue", amWorldName, rodrigueStats);
const killua = createCharacter("Killua", amWorldName, killuaStats);
const dedue = createCharacter("Dedue", amWorldName, dedueStats);
const gaeli = createCharacter("Gaeli", amWorldName, gaeliStats);
const dan = createCharacter("Dan", amWorldName, danStats);
const edelgard = createCharacter("Edelgard", amWorldName, edelgardStats);
const ren = createCharacter("Ren", amWorldName, renStats);
const eugeo = createCharacter("Eugeo", amWorldName, eugeoStats);
const stark = createCharacter("Stark", amWorldName, starkStats);
const hubert = createCharacter("Hubert", amWorldName, hubertStats);
const veldora = createCharacter("Veldora", amWorldName, veldoraStats);
const gojo = createCharacter("Gojo", amWorldName, gojoStats);
const yuri = createCharacter("Yuri", amWorldName, yuriStats);
const ashe = createCharacter("Ashe", amWorldName, asheStats);
const kyojuro = createCharacter("Kyojuro", amWorldName, kyojuroStats);
const shoto = createCharacter("Shoto", amWorldName, shotoStats);

export function getAMChars() {
  return [
    dimitri,
    felix,
    alric,
    echo,
    eugeo,
    summer,
    navee,
    sylvain,
    killua,
    gaeli,
    dan,
    stark,
    hubert,
    veldora,
    gojo,
    rodrigue,
    dedue,
    ren,
    edelgard,
  ];
}

import React from "react";
import CharCreate from "./pages/CharCreate.jsx";
import DiceRoller from "./pages/DiceRoller.jsx";
import { NavLink, Title } from "@mantine/core";
import { IconUser, IconDice5 } from "@tabler/icons-react";
import { createBrowserRouter } from "react-router-dom";

const routes = [
  {
    name: "Dice Roller",
    path: "/",
    element: <DiceRoller />,
    Icon: IconDice5,
  },
  {
    name: "Character Creator",
    path: "/character-create",
    element: <CharCreate />,
    Icon: IconUser,
  },
];

export const router = createBrowserRouter(routes);

export default function Routes() {
  return (
    <>
      {routes.map((routeItem) => (
        <NavLink
          href={routeItem.path}
          label={<Title order={4}>{routeItem.name}</Title>}
          leftSection={<routeItem.Icon size="1rem" stroke={1.5} />}
        />
      ))}
    </>
  );
}

import { amWorldName, getAMChars } from "./AmData";
import { bgWorldName, getBgChars } from "./bgData";

export function getCharacter(world, name) {
  let charactersFromWorld = [];

  switch (world) {
    case amWorldName:
      charactersFromWorld = getAMChars();
      break;
    case bgWorldName:
      charactersFromWorld = getBgChars();
      break;
    default:
      console.log("World " + world + " is not recognized!");
      break;
  }

  let char = charactersFromWorld.find((char) => char.getName() === name);

  if (char) {
    return char;
  } else {
    console.log(
      "Character " + name + " from world " + world + " was not found!",
    );
  }

  return {};
}

export function getAllWorlds() {
  return [amWorldName, bgWorldName];
}

export function getCharactersFromWorld(world) {
  switch (world) {
    case amWorldName:
      return getAMChars();
    case bgWorldName:
      return getBgChars();
    default:
      console.log("World " + world + " is not recognized!");
      return {};
  }
}

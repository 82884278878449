import React from "react";
import { Center, Title } from "@mantine/core";

export default function TotalStatPoints({ currentStatPoints }) {
  return (
    <Center>
      <Title order={1}>{currentStatPoints}</Title>
    </Center>
  );
}

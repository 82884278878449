import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Center, NativeSelect, SimpleGrid } from "@mantine/core";
import Stats from "../character/Stats";
import { getAllWorlds, getCharacter, getCharactersFromWorld } from "../db/Data";
import stats from "../character/Stats";

const onSubmit = (data) => console.log("yoooo");
const worldList = getAllWorlds();

function getCharNamesFromWorld(world) {
  let characters = getCharactersFromWorld(world);
  if (!characters) {
    console.log("Characters not found!");
    return [];
  }

  let namesUnsorted = characters.map((character) => character.getName());
  return namesUnsorted.sort();
}

function getModifier(world, characterName, statName) {
  let char = getCharacter(world, characterName);

  if (!char) {
    console.log(
      "Character " + characterName + " in world " + world + " was not found!",
    );
  }

  // get id from label
  let statEntry = stats.find((stat) => stat.label === statName);
  return char.getModifierValue(statEntry.id);
}

export default function CharacterDiceSelectForm({ changeModifier }) {
  const onChooseWorld = (world) => {
    setWorld(world);
    setCharList(getCharNamesFromWorld(world));
    setCharacter(getCharNamesFromWorld(world)[0]);
  };

  const onChooseChar = (char) => {
    setCharacter(char);
    changeModifier(getModifier(world, character, stat));
  };

  const onChooseStat = (stat) => {
    setStat(stat);
    changeModifier(getModifier(world, character, stat));
  };

  const initChar = getCharactersFromWorld(worldList[0])[0];
  const initCharList = getCharNamesFromWorld(worldList[0]);

  const [world, setWorld] = useState(worldList[0]);
  const [character, setCharacter] = useState(initChar.getName());
  const [charList, setCharList] = useState(initCharList);
  const [stat, setStat] = useState(stats[0].label);

  changeModifier(getModifier(world, character, stat));

  return (
    <Center>
      <SimpleGrid cols={{ base: 1, md: 2, lg: 3 }} w="100%">
        <NativeSelect
          label="RP World"
          description="The world the character is coming from"
          value={world}
          onChange={(e) => onChooseWorld(e.currentTarget.value)}
          data={worldList}
        />
        <NativeSelect
          label="Character"
          description="The character to roll for"
          value={character}
          onChange={(e) => onChooseChar(e.currentTarget.value)}
          data={charList}
        />
        <NativeSelect
          label="Stat"
          description="The stat to roll for"
          value={stat}
          onChange={(e) => onChooseStat(e.currentTarget.value)}
          data={Stats.map((stats) => stats.label)}
        />
      </SimpleGrid>
    </Center>
  );
}

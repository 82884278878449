import React from "react";
import { useFormContext } from "react-hook-form";
import TotalStatPoints from "./TotalStatPoints";
import StatInput from "./StatInput";
import { Button, Center, Box, SimpleGrid } from "@mantine/core";
import stats from "./Stats";
import { TextInput } from "react-hook-form-mantine";
import { maxStatTotal } from "./Stats";

const onSubmit = (data) => console.log(data);

export default function StatForm() {
  const context = useFormContext();
  const currentStats = context.watch(stats.map((stat) => stat.id));
  const currentStatPoints =
    maxStatTotal -
    currentStats
      .map((stat) => parseInt(stat || 0))
      .reduce((curr, acc) => curr + acc, 0);

  console.log(currentStats);

  return (
    <form onSubmit={context.handleSubmit(onSubmit)}>
      <TotalStatPoints currentStatPoints={currentStatPoints} />
      <Box>
        <Center>
          <SimpleGrid cols={{ base: 1, md: 2, lg: 3 }} w="100%">
            <TextInput
              label="Character Name"
              description="What's the character's name?"
              id="name"
              {...context.register("name")}
            />
            <TextInput
              label="RP World"
              description="What RP world is this character from?"
              id="world"
              {...context.register("world")}
            />
            <TextInput
              label="Player"
              description="Who is the real-life player of this character?"
              id="player"
              {...context.register("player")}
            />
            {stats.map((stat) => (
              <StatInput
                key={stat.id}
                id={stat.id}
                label={stat.label}
                description={stat.description}
              />
            ))}
          </SimpleGrid>
        </Center>
        <Center>
          <Button variant="filled" m="2em">
            Create Character
          </Button>
        </Center>
      </Box>
    </form>
  );
}

import { RouterProvider } from "react-router-dom";
import Routes, { router } from "./Routes.jsx";
import "@mantine/core/styles.css";
import {
  AppShell,
  Burger,
  createTheme as createThemeMantine,
  Group,
  MantineProvider,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { GiDiceEightFacesEight } from "react-icons/gi";

function App() {
  const mantineTheme = createThemeMantine({
    /** Put your mantine theme override here */
    autoContrast: true,
    colors: {
      pink: [
        "#ffecf8",
        "#f8d8e8",
        "#ebafcd",
        "#df84b1",
        "#d4609a",
        "#ce498b",
        "#cc3c83",
        "#b52e71",
        "#a32664",
        "#8f1a57",
      ],
    },
  });

  const [opened, { toggle }] = useDisclosure();

  return (
    //<CharacterProvider>
    <MantineProvider theme={mantineTheme} defaultColorScheme="auto">
      <AppShell
        header={{ height: 60 }}
        navbar={{
          width: 300,
          breakpoint: "sm",
          collapsed: { mobile: !opened },
        }}
        padding="md"
      >
        <AppShell.Header>
          <Group h="100%" px="md">
            <Burger
              opened={opened}
              onClick={toggle}
              hiddenFrom="sm"
              size="sm"
            />
            <ThemeIcon>
              <GiDiceEightFacesEight
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#dc79aa",
                  color: "white",
                }}
              />
            </ThemeIcon>
            <Title order={1}>Tara RP</Title>
          </Group>
        </AppShell.Header>
        <AppShell.Navbar p="md">
          <Routes />
        </AppShell.Navbar>
        <AppShell.Main>
          <RouterProvider router={router} />
        </AppShell.Main>
      </AppShell>
    </MantineProvider>
    //</CharacterProvider>
  );
}

export default App;

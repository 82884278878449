import { Box, Button, Stack, Title } from "@mantine/core";
import DiceSelect from "./DiceSelect";
import React, { useState } from "react";
import DiceResult from "./DiceResult";
import { getDiceValue, rollRandomResultWithMod } from "./Dice";
import CharacterDiceSelectForm from "./CharacterDiceSelectForm";

export default function CharacterDiceRoll() {
  const [diceType, setDiceType] = useState("d20");
  const [rollResult, setRollResult] = useState("20");
  const [modifier, setModifier] = useState(0);

  function rollCharDice() {
    setRollResult(rollRandomResultWithMod(getDiceValue(diceType), modifier));
  }

  function handleDiceChange(diceType) {
    let diceValue = getDiceValue(diceType);
    setDiceType(diceType);
    setRollResult(diceValue.toString());
  }

  return (
    <Box mt={5}>
      <Stack align="center" gap="md">
        <Title order={1}>Character Dice Roller</Title>
        <DiceSelect value={diceType} change={handleDiceChange}></DiceSelect>

        <CharacterDiceSelectForm
          changeModifier={setModifier}
        ></CharacterDiceSelectForm>
        <Title order={2}>Modifier: {modifier}</Title>

        <DiceResult rollResult={rollResult}></DiceResult>
        <Button
          variant="filled"
          onClick={(e) => rollCharDice(e.currentTarget.value)}
          color="pink"
        >
          Roll Dice
        </Button>
      </Stack>
    </Box>
  );
}

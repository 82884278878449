import {
  charmId,
  defenseId,
  magicId,
  skillId,
  speedId,
  strengthId,
} from "../character/Stats";
import createCharacter from "../character/Character";

export const bgWorldName = "Baldur's Gate";

const astarionStats = new Map([
  [strengthId, 2],
  [magicId, -2],
  [speedId, 5],
  [charmId, 1],
  [skillId, 5],
  [defenseId, 2],
]);

const galeStats = new Map([
  [strengthId, -5],
  [magicId, 5],
  [speedId, 3],
  [charmId, 3],
  [skillId, 5],
  [defenseId, -4],
]);

const astarion = createCharacter("Astarion", bgWorldName, astarionStats);
const gale = createCharacter("Gale", bgWorldName, galeStats);

export function getBgChars() {
  return [astarion, gale];
}

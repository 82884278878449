import React from "react";
import { Tabs, Center } from "@mantine/core";

import SimpleDiceRoll from "../components/roller/SimpleDiceRoll";
import CharacterDiceRoll from "../components/roller/CharacterDiceRoll";
import classes from "../css/tabs.module.css";

export default function DiceRoller() {
  return (
    <Center>
      <Tabs defaultValue="simple" classNames={classes}>
        <Tabs.List grow>
          <Tabs.Tab value="simple">Simple Dice Roller</Tabs.Tab>
          <Tabs.Tab value="character">Character Dice Roller</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="simple">
          <SimpleDiceRoll />
        </Tabs.Panel>

        <Tabs.Panel value="character">
          <CharacterDiceRoll />
        </Tabs.Panel>
      </Tabs>
    </Center>
  );
}

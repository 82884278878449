import React from "react";
import { Select } from "@mantine/core";

const diceTypes = ["d20", "d12", "d8", "d6", "d4"];

export default function DiceSelect({ value, change }) {
  function handleSelectChange(event) {
    change(event);
  }

  return (
    <Select
      label="Dice Type"
      placeholder="d20"
      data={diceTypes}
      value={value}
      onChange={handleSelectChange}
    />
  );
}

export const strengthId = "strength";
export const magicId = "magic";
export const defenseId = "defense";
export const speedId = "speed";
export const charmId = "charm";
export const skillId = "skill";

export const maxStatTotal = 10;

const stats = [
  {
    id: strengthId,
    label: "Strength",
    description:
      "Do you even lift, bro? What's their physical fighting strength?",
  },
  { id: magicId, label: "Magic", description: "How powerful is their magic?" },
  {
    id: speedId,
    label: "Speed",
    description: "How fast can they run and react?",
  },
  {
    id: charmId,
    label: "Charm",
    description:
      "How likely can they convince, persuade, or hide their feelings?",
  },
  {
    id: skillId,
    label: "Skill",
    description: "How good are they at lock picking, solving puzzles, stealth?",
  },
  {
    id: defenseId,
    label: "Defense",
    description: "How well can they withstand hits?",
  },
];

export default stats;

import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import StatForm from "../components/character/StatForm";

export default function CharCreate() {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <StatForm></StatForm>
    </FormProvider>
  );
}

export default function createCharacter(
  charName,
  charWorld,
  charStats,
  charPlayer,
) {
  const name = charName;
  const world = charWorld;
  const statMap = charStats;
  const player = charPlayer;

  function getStats() {
    return statMap;
  }

  function getWorld() {
    return world;
  }

  function getName() {
    return name;
  }

  function getPlayer() {
    return player;
  }

  function getModifierValue(sId) {
    return statMap.get(sId);
  }

  return {
    getStats,
    getWorld,
    getName,
    getPlayer,
    getModifierValue,
  };
}

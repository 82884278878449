import classes from "../../css/dice.module.css";
import { Box, Image, Title } from "@mantine/core";
import D20Image from "../../static/d20.PNG";
import React from "react";

export default function DiceResult({ rollResult }) {
  return (
    <Box className={classes.diceImage}>
      <Image src={D20Image} alt="dice" />
      <Title className={classes.diceText} order={1} c="black">
        {rollResult}
      </Title>
    </Box>
  );
}

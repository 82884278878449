export function getDiceValue(dice) {
  switch (dice) {
    case "d4":
      return 4;
    case "d6":
      return 6;
    case "d8":
      return 8;
    case "d12":
      return 12;
    case "d20":
      return 20;
    default:
      console.log(
        "Incorrect diceType passed! " + dice + " is not a recognized dice type",
      );
      return 1;
  }
}

export function rollRandomResult(value) {
  return 1 + Math.floor(Math.random() * value);
}

export function rollRandomResultWithMod(value, modifier) {
  let base = rollRandomResult(value);

  if (base === 20) {
    return base;
  } else {
    return rollRandomResult(value) + modifier;
  }
}

import React from "react";
import { useFormContext } from "react-hook-form";
import { NumberInput } from "react-hook-form-mantine";

export default function StatInput({ id, label, description }) {
  const { register } = useFormContext();

  return (
    <NumberInput
      id={id}
      label={label}
      description={description}
      {...register(id)}
      defaultValue="0"
      min={-5}
      max={5}
    />
  );
}
